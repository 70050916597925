"use client"

import CollectorForm from "@/components/myComponents/collector-form"


const CollectorPage = () => {
  
  return (
    <div>
        <CollectorForm />
    </div>
  )
}

export default CollectorPage