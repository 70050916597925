'use client'

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { usePathname } from "next/navigation";
import { enrollEmail, getProjectDetails } from "@/lib/actions";
import { useRouter } from "next/navigation";
import { Check, LoaderCircle } from "lucide-react";

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "This field has to be filled." })
    .email("This is not a valid email."),
});

const CollectorForm = () => {
  const router = useRouter();
  const pathname = usePathname();

  const [normalizedPathname, setNormalizedPathname] = useState("");
  const [projectDetails, setProjectDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectId, setProjectId] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false)

  useEffect(() => {
    const normalized = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    setNormalizedPathname(normalized);
    
    const fetchData = async () => {
      const url = window.location.href;
      const parts = url.split('/');
      const lastPart = parts[parts.length - 1];
      setProjectId(lastPart);

      try {
        const projectDetails = await getProjectDetails({ projectId: lastPart });
        setProjectDetails(projectDetails);
      } catch (error) {
        console.error("Error fetching project details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    const email = values.email;
    const projectId = normalizedPathname;

    const taken = await enrollEmail({ projectId, email });
    setIsSubmitting(false);
    setIsSubmitted(true);
    form.reset();
  }

  if (!normalizedPathname) {
    return null;
  }

  return (
    <div className="min-h-screen bg-[#15202B] text-white flex flex-col justify-center items-center p-8">
      {
        loading ? 
        <div className="flex items-center justify-center text-white mt-24">
          <LoaderCircle className="animate-spin text-4xl" />
        </div> :
        <div className="w-full max-w-md">
          {
            isSubmitted ? 
            <div className="flex flex-col sm:flex-row items-center justify-center gap-2 text-2xl text-center">
              <Check className="h-48 w-48" />
              <p className="text-base sm:text-2xl">Your email has been successfully submitted. Thank you for your interest!</p>
            </div>:
            <div>
              <div className="mb-6 text-center">
                <h1 className="text-3xl font-bold break-words">
                  {projectDetails?.name}
                </h1>
                <h2 className="text-lg mt-2 break-words">
                  {projectDetails?.description}
                </h2>
              </div>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter your email..." {...field} className="w-full text-black" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button disabled={isSubmitting} type="submit" className="w-full bg-white hover:bg-white/80 text-[#15202B] font-bold py-2 px-4 rounded">
                    {
                      isSubmitting ? <LoaderCircle className="animate-spin" /> : <p>Submit</p>
                    }
                  </Button>
                </form>
              </Form>
            </div>
          }

        </div>
      }
    </div>
  );
};

export default CollectorForm;




